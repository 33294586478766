import React, { Component } from 'react';

const IMAGE_PATH = '/images/icons/social/';
const IMAGE_NAME_SUFFIX = '_35.png';

function SocialItem(props) {
  return (
    <a href={props.link} target="_blank" rel="noreferrer">
      <img
        alt={props.name}
        title={props.name}
        src={IMAGE_PATH + props.name.replace(' ', '_').toLowerCase() + IMAGE_NAME_SUFFIX}
        className="social_icons"
      />
    </a>
  );
}

export default SocialItem;
