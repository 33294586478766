import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as CONSTANTS from '../constants';

const PAGE_TITLE = 'App Development';

function AppDevelopment() {
  return (
    <div>
      <Helmet>
        <title>
          {PAGE_TITLE} | {CONSTANTS.GLOBAL_NAME}
        </title>
      </Helmet>
      <h3>{PAGE_TITLE}</h3>
      In addition to web design, I also serve as a mobile application developer and have created and/or collaborated on
      projects for Android, iOS, and Windows Phone. Some of my published work is shown below. For development inquires,
      please{' '}
      <Link to="/contact" target="_blank">
        contact me
      </Link>
      .<br />
      <br />
      <div className="card">
        <div className="card-header">Number Guesser</div>
        <div className="card-body">
          <div className="row">
            <div className="col-6 col-sm-3">
              <img src="/images/thumb/app/number_guesser.png" alt="Number Guesser" width="200" />
            </div>
            <div className="col-6 col-sm-9">
              A simple number guessing application for Windows Phone.
              <br />
              <br />
              Enter your guess, and the game will tell you whether it is too high or too low. Keep guessing until you
              determine the number, and then play again to try and beat your best scores!
              <br />
              Includes support for multiple number ranges, as well as a detailed statistics panel that shows your best
              and average scores with a count of played games.
              <br />
              <br />
              <a href="/images/thumb/app/number_guesser_screen_guess.png" target="_blank" rel="noreferrer">
                <img
                  src="/images/thumb/app/number_guesser_screen_guess.png"
                  alt="Number Guesser Screenshot Guess Page"
                  width="160"
                />{' '}
              </a>{' '}
              <a href="/images/thumb/app/number_guesser_screen_statistics.png" target="_blank" rel="noreferrer">
                <img
                  src="/images/thumb/app/number_guesser_screen_statistics.png"
                  alt="Number Guesser Screenshot Statistics Page"
                  width="160"
                />{' '}
              </a>{' '}
              <a href="/images/thumb/app/number_guesser_screen_settings.png" target="_blank" rel="noreferrer">
                <img
                  src="/images/thumb/app/number_guesser_screen_settings.png"
                  alt="Number Guesser Screenshot Settings Page"
                  width="160"
                />{' '}
              </a>{' '}
              <a href="/images/thumb/app/number_guesser_screen_winner.png" target="_blank" rel="noreferrer">
                <img
                  src="/images/thumb/app/number_guesser_screen_winner.png"
                  alt="Number Guesser Screenshot Winner Page"
                  width="160"
                />
              </a>
              <br />
              <br />
              <a
                href="http://www.windowsphone.com/en-us/store/app/number-guesser/950673b6-3371-e011-81d2-78e7d1fa76f8?type=phoneapp&id=950673b6-3371-e011-81d2-78e7d1fa76f8&signin=true"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/images/WindowsPhone.png"
                  alt="Download from Windows Phone Store"
                  title="Download from Windows Phone Store"
                />
              </a>
              &nbsp;
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppDevelopment;
