import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import * as CONSTANTS from '../constants';

const PAGE_TITLE = 'Writing';

function Writing() {
  return (
    <div>
      <Helmet>
        <title>
          {PAGE_TITLE} | {CONSTANTS.GLOBAL_NAME}
        </title>
      </Helmet>
      <h3>{PAGE_TITLE}</h3>
      <p>
        While I occasionally feature content on this site, I have previously written hundreds of articles for a variety
        of websites including software/hardware reviews, interviews, day-to-day news, and more.
        <br />
        <br />I have also served as registered press and attended the Consumer Electronics Show (&quot;CES&quot;) and
        the Electronic Entertainment Expo (&quot;E3&quot;), in addition to having content linked from other popular
        websites such as Engadget, Joystiq and more.
      </p>
      <strong>Example Content:</strong>
      <br />
      <ul>
        <li>
          <a target="_blank" rel="noreferrer" href="http://mobilitydigest.com/mobility-digest-review-samsung-nexus-s/">
            Mobility Digest Review: Samsung Nexus S
          </a>
          &nbsp;
          <a className="text-muted" href="writing_backup/MD-NexusS.pdf" target="_blank">
            (archive)
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="http://mobilitydigest.com/mobility-digest-review-bracketron-universal-gps-grip-it-vent-mount/"
          >
            Mobility Digest Review: Bracketron Universal GPS Grip-iT Vent Mount
          </a>
          &nbsp;
          <a className="text-muted" href="writing_backup/MD-BracketronMount.pdf" target="_blank">
            (archive)
          </a>
        </li>
        <li>
          <a target="_blank" rel="noreferrer" href="http://mobilitydigest.com/windows-phone-7-updates-from-ces-2011/">
            Mobility Digest: Live at CES 2011 - Exclusive Windows Phone 7 updates
          </a>
          &nbsp;
          <a className="text-muted" href="writing_backup/MD-CES_2011_MS_Recap.pdf" target="_blank">
            (archive)
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="http://mobilitydigest.com/mobility-digest-interview-luigi-violin-creator-of-wp7applist/"
          >
            Mobility Digest Interview: Luigi Violin, creator of WP7Applist
          </a>
          &nbsp;
          <a className="text-muted" href="writing_backup/MD-WP7Applist_Interview.pdf" target="_blank">
            (archive)
          </a>
        </li>
        <li>
          Xbox 360 Digest Review: Grand Theft Auto: Episodes from Liberty City&nbsp;
          <a className="text-muted" href="writing_backup/X3D-GTALibertyCity.pdf" target="_blank">
            (archive)
          </a>
        </li>
        <li>
          Xbox 360 Digest Review: MLB 2K11&nbsp;
          <a className="text-muted" href="writing_backup/X3D-MLB2K11.pdf" target="_blank">
            (archive)
          </a>
        </li>
        <li>
          Xbox 360 Digest: Live at E3 2010 - Press Conferences Recap&nbsp;
          <a className="text-muted" href="writing_backup/X3D-E3_2010_PressConfRecap.pdf" target="_blank">
            (archive)
          </a>
        </li>
        <li>
          Xbox 360 Digest: Live at E3 2010 - Audio Accessories Roundup&nbsp;
          <a className="text-muted" href="writing_backup/X3D-E3_2010_AudioRecap.pdf" target="_blank">
            (archive)
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Writing;
