import React, { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState(null);
  const [captchaToken, setCaptchaToken] = useState(null);
  const recaptchaRef = useRef(null);

  const onChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!captchaToken) {
      setStatus('Please complete the reCAPTCHA verification.');
      return;
    }

    setStatus('Sending...');

    const details = {
      name,
      email,
      message,
      captchaToken
    };

    try {
      const response = await fetch(process.env.REACT_APP_SERVICES_HOST + '/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(details)
      });

      const result = await response.json();

      if (result.status === 'success') {
        setStatus('Message sent successfully!');
        setMessage('');
      } else {
        setStatus(result.message || 'There was an error sending your message.');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setStatus('An unexpected error occurred. Please try again later.');
    } finally {
      setCaptchaToken(null);
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="name">Name:</label>
        <br />
        <input
          type="text"
          id="name"
          placeholder="(required)"
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="email">Email:</label>
        <br />
        <input
          type="email"
          id="email"
          placeholder="(required)"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="message">Message:</label>
        <br />
        <textarea
          rows="5"
          cols="38"
          id="message"
          placeholder="(required)"
          required
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>
      <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY} onChange={onChange} />
      <br />
      <button type="submit" id="submit">
        Submit
      </button>
      {status && (
        <p id="statusMessage" className="status">
          {status}
        </p>
      )}
    </form>
  );
};

export default ContactForm;
