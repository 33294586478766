import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import * as CONSTANTS from '../constants';
import ContactForm from '../components/ContactForm.js';

const PAGE_TITLE = 'Contact';

function Contact() {
  return (
    <div>
      <Helmet>
        <title>
          {PAGE_TITLE} | {CONSTANTS.GLOBAL_NAME}
        </title>
      </Helmet>
      <h3>{PAGE_TITLE}</h3>
      For general inquiries or to obtain a copy of my r&#233;sum&#233;, please contact me via social media or the form
      below.
      <ContactForm />
    </div>
  );
}

export default Contact;
