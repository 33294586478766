import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as CONSTANTS from '../constants';

const PAGE_TITLE = 'Home';

function Home() {
  return (
    <div>
      <Helmet>
        <title>
          {PAGE_TITLE} | {CONSTANTS.GLOBAL_NAME}
        </title>
        <script async src="https://platform.twitter.com/widgets.js"></script>
      </Helmet>
      <h3>{PAGE_TITLE}</h3>
      <div id="page_index">
        Greetings! My name is Patrick Kortendick, and I&apos;m a software engineering consultant based near Minneapolis,
        Minnesota.
        <br />
        <br />
        I&apos;ve had an interest in computers and programming since I was young, ultimately leading to me completing my
        degree in Electrical and Computer Engineering. After school, I spent time working on a major retailer&apos;s
        Ecommerce site, serving as both a full-stack engineer and development team lead. I then moved into consulting,
        where my current interests include web development, application security, and open source technologies.
        <br />
        <br />
        In my personal life, I enjoy watching/attending sporting events, listening to podcasts, playing with technology,
        cycling/walking, aviation, and playing video games (if time allows). I&apos;m also a dad and{' '}
        <Link to="/travel_map">avid traveler</Link>, having visited all 50 U.S. states and several dozen countries.
        <br />
        <br />
        Thanks for visiting my site, and feel free to contact me using any of the methods in the footer below.
        <div id="twitter_embed">
          <a
            className="twitter-timeline"
            data-width="700"
            data-height="700"
            href="https://twitter.com/Pkort?ref_src=twsrc%5Etfw"
          >
            Tweets by Pkort
          </a>
        </div>
      </div>
    </div>
  );
}

export default Home;
